import { ApolloClient, ApolloLink, ApolloProvider, FetchResult, HttpLink, InMemoryCache, Observable, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getOperationDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/nextjs';
import React from 'react';

import ENV from '~/constants/ENV';
import ROUTES from '~/constants/ROUTES';
import { clearTokensAndRedirect, getAccessToken, getLtiToken } from '~/helpers/authTokens';
import censorPasswords from '~/helpers/censorPasswords';
import getCurrentUrl from '~/helpers/getCurrentUrl';
import Logger from '~/helpers/logger';
import { useReactNativeObj } from '~/hooks/useReactNativeObj';

const abortController = new AbortController();

const logger = new Logger('ApolloClientProviderComponent');

const retryLink = new RetryLink();

const authLink = setContext((_, { headers }): Record<string, unknown> => {
  const accessToken = getAccessToken();
  const ltiToken = getLtiToken();
  const extraHeaders: Record<string, unknown> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'encourage-debug': window.e4s_sendDebugHeader || false,
  };

  if (accessToken) {
    // This is to guarantee that we use the newest JWT token
    extraHeaders.Authorization = `Bearer ${accessToken}`;
  }

  if (ltiToken) {
    extraHeaders['lti-authorization'] = ltiToken;
  }

  return {
    headers: {
      ...headers,
      ...extraHeaders,
    },
  };
});

const sentryBreadcrumbLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  const definition = getOperationDefinition(operation.query);
  // Start performance monitoring
  const fetchSpan = Sentry?.startInactiveSpan({
    name: `${definition?.operation || 'unknown'} ${operation.operationName}`,
    op: `http.graphql.${definition?.operation || 'query'}`,
  });
  return forward(operation).map(data => {
    // Called after server responds
    // End performance monitoring
    fetchSpan?.finish();

    Sentry?.addBreadcrumb({
      category: 'graphql',
      data: {
        // Mask sensitive data
        data: JSON.stringify(censorPasswords(data), null, 2),
        status_code: `${definition?.operation || 'unknown'} ${operation.operationName}`,
        url: process.env.DEBUG_API_ENDPOINT_GRAPHQL || ENV.API_ENDPOINT_GRAPHQL || '',
        // Mask sensitive data
        variables: JSON.stringify(censorPasswords(operation.variables), null, 2),
      },
      level: 'info',
      type: 'http',
    });

    return data;
  });
});

// Error handler for the apollo client.
const errorLink = onError(({ graphQLErrors, networkError }): Observable<FetchResult> | undefined => {
  // Log all graphql errors.
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      logger.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations?.map(l => `Column: ${l.column} Line: ${l.line}`).join(', ') ?? 'unknown'}, Path: ${
          path?.join(', ') ?? 'unknown'
        }`,
      );
      if (message === 'Unauthorized') {
        const currentPath = getCurrentUrl();
        clearTokensAndRedirect(ROUTES.SIGNIN, { returnUrl: currentPath });
      }
      return null;
    });
  }

  // If the error has status 401 or 403 (Unauthorized or Forbidden), log it, remove all tokens and redirect user to sign out.
  // This occurs if the user has tried to access a link without being signed in.
  if (networkError) {
    logger.error('networkError', networkError);
    if ('statusCode' in networkError && (networkError.statusCode === 401 || networkError.statusCode === 403)) {
      // Using the signout route here to reuse the returnUrl queryParam logic, which means that once the user signs in they will be redirected
      // to the page they tried to access.
      clearTokensAndRedirect(ROUTES.SIGNOUT);
    }
  }

  return undefined;
});

const httpLink = new HttpLink({
  // https://github.com/getsentry/sentry-javascript/issues/8345
  fetchOptions: {
    signal: abortController.signal,
  },
  uri: () => process.env.DEBUG_API_ENDPOINT_GRAPHQL || ENV.API_ENDPOINT_GRAPHQL || '',
});

const links = [retryLink, authLink, errorLink, sentryBreadcrumbLink, httpLink];

export const cache = () =>
  new InMemoryCache({
    typePolicies: {
      CollegeCollection: {
        keyFields: ['id', 'meta', 'colleges', ['mcoid']],
      },
      EncourageUser: {
        keyFields: ['visitorId'],
      },
      LearnerJourney: {
        keyFields: ['id', 'journeyType'],
      },
      LearnerMilestoneCategory: {
        keyFields: ['id', 'journeyType'],
      },
      LearnerMilestoneChecklistItem: {
        // This SHOULD be temporary. The reason it's needed right now is that in stage (at least) there are checkListItems that have the same id
        // within the same milestone task.
        keyFields: ['id', 'text'],
      },
      MilestoneCategorySummary: {
        keyFields: ['id', 'learnerMilestoneCategory', ['id', 'journeyType']],
      },
      MilestoneCmsArticleSchema: {
        keyFields: ['cmsArticleId', 'milestoneId'],
      },
    },
  });

const getApolloClient = (version?: string) => {
  return new ApolloClient({
    cache: cache(),
    // https://www.apollographql.com/docs/react/development-testing/developer-tooling/#apollo-client-devtools
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: from(links),
    name: 'ENCOURAGE',
    version,
  });
};

// eslint-disable-next-line import/prefer-default-export
export const ApolloClientProviderComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const reactNativeObj = useReactNativeObj();
  const version = React.useMemo(() => getApolloClient(reactNativeObj?.build?.marketingVersion ?? ENV.APP_VERSION), [reactNativeObj?.build?.marketingVersion]);

  return React.useMemo(() => <ApolloProvider client={version}>{children}</ApolloProvider>, [version, children]);
};
